.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Additions */
.text-align-right {
  text-align: right;
}
.no-ul-bullet {
  list-style-type: none;
}
.vertical-align-middle { 
  vertical-align: middle;
}
.align-self-end {
  align-self: flex-end;
}
.no-margins {
  margin: 0;
}
.no-bottom-margin {
  margin-bottom: 0;
}
.small-bottom-margin {
  margin-bottom: .5em;
}
.small-margin-right {
  margin-right: .5rem;
}
.small-margin-left {
  margin-left: .5rem;
}
.large-margin-left {
  margin-left: 2.5rem;
}

.exam-answer {
  cursor: pointer;
  border: 1px solid transparent;
}
.exam-answer:hover {
  border: 1px solid black;
}
.exam-answer-input {
  max-width: 4%;
  padding-right: 1em;
  display: inline-block;
  vertical-align: middle;
}
.exam-answer-text {
  max-width: 95%;
  display: inline-block;
  vertical-align: middle;
}

hr.slim-hr {
  margin:.5em 0;
}
.sortable-btn span {
  font-weight: bold;
}

.sortable-btn {
  cursor: pointer;
}
div.label-field-set label{
  font-size: large;
  vertical-align: middle;
  /*font-weight: bold;*/
}
label.label-style-1 {
  font-size: large;
  display:inline-block;
  vertical-align: middle;
  /*font-weight: bold;*/
}
/*SEBS*/
/*
.sortable-btn {
  color:white;
  cursor: pointer;
}
*/

#content ul.no-extra-margins li,
ul.no-extra-margins li {
  margin-bottom:0;
}
#content ul.no-extra-margins ul,
ul.no-extra-margins ul {
  margin-top:0;
  margin-bottom:0;
}

/* FOUNDATION OVERRIDES */
div.button-group {
  /*flex-wrap: wrap;*/
  margin-top:10px;
}
div.button-group.button-group-override .button {
  margin-top:1px;
  /*flex: 1 1 auto;*/ 
}
#content div.button-group .button.primary.is-selected, 
div.button-group .button.primary.is-selected{
  background-color: #ffae00;
  color: black;
}

/* NJAES Additions  */

/* NJAES OVERRIDES */
.label.success,
.button.success.override-sebs { 
  /* Like why lol*/
  color:black;
}
div#root a.button.tiny, 
div#root button.button.tiny {
  padding: .5em .5em;
  font-size: .75rem;
}
#content table {
  width:100%;
}
#site-nav-wrapper { 
  margin-top: 34px;
}
#content-wrapper {
  position: static;
}
#site-nav-main li.help-table-cell {
  display:block;
}
@media screen and (min-width:64em) {
  #site-nav-main li.help-table-cell {
   display:table-cell
  }
}
@media screen and (max-width: 39.9375em) { /* Small screens */
  #site-nav-wrapper { 
    margin-top: 24px;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) { /* Medium only */
  .title-bar {
    margin-top:33px;
  }
  #site-nav-wrapper { 
    margin-top: 0;
  }
}
#main-col > #content, 
#main-col > #content-alt {
  padding-top:1em;
}
/* NJAES CSS FIXES  (that are present on the njaes.rutgers.edu site) */
.menu>li.is-accordion-submenu-parent>a::after,
.menu>li.is-dropdown-submenu-parent>a::after {
 right:1em;
}
/* SEBS Overrides */

#content .button.success,
.button.success.override-sebs { 
  background-color: #3adb76;
}

#content .button.large {
  font-size: 1.25rem;
  padding: .85em 1em;
}
#content .button.success,
a.button.success.override-sebs { 
  color:black;
}
.button.success.override-sebs:hover,
.button.success.override-sebs:active,
.button.success.override-sebs:focus { 
  background-color: #22bb5b;
}
html.has-certificate {
  background-color: white !important;
}
div.tabs-override > ul.accordion > li {
  margin-bottom:0;
}
div.tabs-override > ul.tabs > li {
  width:auto;
}
div.tabs-override > ul.tabs::after,
div.tabs-override > ul.tabs::before {
  content: unset;
}

/* LUCAS OVERRIDES */
.table-left-align-all tbody td, 
.table-left-align-all thead th {
  text-align: left;
}

@media only screen and (max-width:40em) {
  #site-search-button {
    /* Overriding; no reason to make this larger when the size of the screen is narrow */
    font-size: 1.25rem;
  }
}